globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"54b95eebf71023f36419c86b3874ea1fc699320d"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/plp";
import * as Sentry from '@sentry/nextjs';

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

    initialScope: {
        tags: {
            application_name: 'PLP',
        },
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate:
        Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0.015,

    // NOTE: the BrowserTracing and Replay was commented out to avoid performance issue
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error'],
        }),
    ],
});

export default Sentry;
